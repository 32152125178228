/* CSS RESET DO NOT REMOVE */
*{
  margin: 0px;
  padding: 0px;
}



/* GLOBAL CSS */



    /* This creates the loading effect on the ai form */
    .load { 
      height: 100%;
      width: 100%;
      left:0;
      right: 0;
      top: 0;
      bottom: 0;
    background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
    background-size: 1800% 1800%;
    
    -webkit-animation: rainbow 18s ease infinite;
    -z-animation: rainbow 18s ease infinite;
    -o-animation: rainbow 18s ease infinite;
      animation: rainbow 18s ease infinite;}
    
    @-webkit-keyframes rainbow {
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }
    @-moz-keyframes rainbow {
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }
    @-o-keyframes rainbow {
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }
    @keyframes rainbow { 
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }









    /* This is the css for the background --- fragile careful on edit */
    .wrapper {
      height: 250vh;
      width: 99%;
      z-index: -2;
      position: absolute;
      top:-100px
    }
    .wrapper div {
      height: 60px;
      width: 60px;
      border: 2px solid rgba(255, 255, 255, 0.7);
      border-radius: 50px;
      position: absolute;
      top: 10%;
      left: 10%;
      animation: 4s linear infinite;
    }
    div .dot {
      height: 10px;
      width: 10px;
      border-radius: 50px;
      background: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 20%;
      right: 20%;
    }
    .wrapper div:nth-child(1) {
      top: 20%;
      left: 20%;
      animation: animate 8s linear infinite;
    }
    .wrapper div:nth-child(2) {
      top: 60%;
      left: 80%;
      animation: animate 10s linear infinite;
    }
    .wrapper div:nth-child(3) {
      top: 40%;
      left: 40%;
      animation: animate 3s linear infinite;
    }
    .wrapper div:nth-child(4) {
      top: 66%;
      left: 30%;
      animation: animate 7s linear infinite;
    }
    .wrapper div:nth-child(5) {
      top: 90%;
      left: 10%;
      animation: animate 9s linear infinite;
    }
    .wrapper div:nth-child(6) {
      top: 30%;
      left: 60%;
      animation: animate 5s linear infinite;
    }
    .wrapper div:nth-child(7) {
      top: 70%;
      left: 20%;
      animation: animate 8s linear infinite;
    }
    .wrapper div:nth-child(8) {
      top: 75%;
      left: 60%;
      animation: animate 10s linear infinite;
    }
    .wrapper div:nth-child(9) {
      top: 50%;
      left: 50%;
      animation: animate 6s linear infinite;
    }
    .wrapper div:nth-child(10) {
      top: 45%;
      left: 20%;
      animation: animate 10s linear infinite;
    }
    .wrapper div:nth-child(11) {
      top: 10%;
      left: 90%;
      animation: animate 9s linear infinite;
    }
    .wrapper div:nth-child(12) {
      top: 20%;
      left: 70%;
      animation: animate 7s linear infinite;
    }
    .wrapper div:nth-child(13) {
      top: 20%;
      left: 20%;
      animation: animate 8s linear infinite;
    }
    .wrapper div:nth-child(14) {
      top: 60%;
      left: 5%;
      animation: animate 6s linear infinite;
    }
    .wrapper div:nth-child(15) {
      top: 90%;
      left: 80%;
      animation: animate 9s linear infinite;
    }
    .wrapper div:nth-child(16){
      top: 30%;
      left: 40%;
      animation: animate 5s linear infinite;
    }
    .wrapper div:nth-child(17) {
      top: 70%;
      left: 20%;
      animation: animate 8s linear infinite;
    }
    .wrapper div:nth-child(18) {
      top: 75%;
      left: 60%;
      animation: animate 10s linear infinite;
    }
    .wrapper div:nth-child(19) {
      top: 50%;
      left: 50%;
      animation: animate 6s linear infinite;
    }
    .wrapper div:nth-child(20) {
      top: 45%;
      left: 20%;
      animation: animate 10s linear infinite;
    }
    .wrapper div:nth-child(21) {
      top: 10%;
      left: 90%;
      animation: animate 9s linear infinite;
    }
    .wrapper div:nth-child(22) {
      top: 20%;
      left: 70%;
      animation: animate 7s linear infinite;
    }
    .wrapper div:nth-child(23) {
      top: 20%;
      left: 20%;
      animation: animate 8s linear infinite;
    }
    .wrapper div:nth-child(24) {
      top: 60%;
      left: 5%;
      animation: animate 6s linear infinite;
    }
    .wrapper div:nth-child(25) {
      top: 90%;
      left: 80%;
      animation: animate 9s linear infinite;
    }
    .wrapper div:nth-child(26){
      top: 30%;
      left: 40%;
      animation: animate 5s linear infinite;
    }
    .wrapper div:nth-child(27) {
      top: 70%;
      left: 20%;
      animation: animate 8s linear infinite;
    }
    .wrapper div:nth-child(28) {
      top: 75%;
      left: 60%;
      animation: animate 10s linear infinite;
    }
    .wrapper div:nth-child(29) {
      top: 50%;
      left: 50%;
      animation: animate 6s linear infinite;
    }
    .wrapper div:nth-child(30) {
      top: 45%;
      left: 20%;
      animation: animate 10s linear infinite;
    }
    .wrapper div:nth-child(31) {
      top: 10%;
      left: 90%;
      animation: animate 9s linear infinite;
    }
    .wrapper div:nth-child(32) {
      top: 20%;
      left: 70%;
      animation: animate 7s linear infinite;
    }
    .wrapper div:nth-child(33) {
      top: 20%;
      left: 20%;
      animation: animate 8s linear infinite;
    }
    .wrapper div:nth-child(34) {
      top: 60%;
      left: 5%;
      animation: animate 6s linear infinite;
    }
    .wrapper div:nth-child(35) {
      top: 90%;
      left: 80%;
      animation: animate 9s linear infinite;
    }
    .wrapper div:nth-child(36){
      top: 30%;
      left: 40%;
      animation: animate 5s linear infinite;
    }
    .wrapper div:nth-child(37) {
      top: 70%;
      left: 20%;
      animation: animate 8s linear infinite;
    }
    .wrapper div:nth-child(38) {
      top: 75%;
      left: 60%;
      animation: animate 10s linear infinite;
    }
    .wrapper div:nth-child(39) {
      top: 50%;
      left: 50%;
      animation: animate 6s linear infinite;
    }
    .wrapper div:nth-child(40) {
      top: 45%;
      left: 20%;
      animation: animate 10s linear infinite;
    }
    .wrapper div:nth-child(41) {
      top: 10%;
      left: 90%;
      animation: animate 9s linear infinite;
    }
    .wrapper div:nth-child(42) {
      top: 20%;
      left: 70%;
      animation: animate 7s linear infinite;
    }
    .wrapper div:nth-child(43) {
      top: 20%;
      left: 20%;
      animation: animate 8s linear infinite;
    }
    .wrapper div:nth-child(44) {
      top: 60%;
      left: 5%;
      animation: animate 6s linear infinite;
    }
    .wrapper div:nth-child(45) {
      top: 90%;
      left: 80%;
      animation: animate 9s linear infinite;
    }
    .wrapper div:nth-child(46){
      top: 30%;
      left: 40%;
      animation: animate 5s linear infinite;
    }




    @keyframes animate {
      0% {
        transform: scale(0) translateY(0) rotate(70deg);
      }
      100% {
        transform: scale(1.3) translateY(-100px) rotate(360deg);
      }
    }











      /* ELEMENTS */
      h2 {
        font-size: 32px;
      }
      html{
        padding-bottom: 10%;
      }
      body {
        background: rgb(34,193,195);
        background: radial-gradient(circle,  rgba(37,145,251,0.98) 0%, rgba(0,7,128,1) 100%);
        width: 100vw;
        height: 100vh;
        padding-bottom: 10%;
        overflow-y: visible;
        overflow-x: hidden;
      }
      a {
        color:rgb(220,220,220);

      }
      /* global classes */
      .section-background-light{
        background-color: rgba(0, 0, 0, 0.259);
        backdrop-filter: blur(7px);
      }
      .fieldIsBold{
        font-weight: bolder;
      }
      .indent{
        text-indent: 15px;
      }
      .underline{
        text-decoration: underline;
      }
      .text-lift-light{
        /* text-shadow: rgb(85 95 107) 1px 0px 10px; */
        color:white;
      }
      .container-shape{
        border-radius: 20px;
        overflow-x: hidden;
      }
      .elevate{
        transform: scale(1);
      }
      .elevate:hover, .elevate:focus{
        transform: scale(1.11);
        /* box-shadow: 1px 1px 20px 1px rgb(33, 32, 32); */
        transition-duration: .25s;
        -webkit-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
      }
      .elevate-icon{
        transform: scale(1);
      }
      .elevate-icon:hover, .elevate-icon:focus{
        transform: scale(1.1);
        transition-duration: .25s;
        -webkit-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
      }
      .card-body{
        padding: .25rem;
      }
      .card-title, .card-text{
        display: flex;
        justify-content: center;
      }
      button{
        color: #fff;
      }

      #merci-text{
        position: relative;
        left: 30px;
        width: 250px;
      }
      .carousel-indicators, .carousel-control-prev, .carousel-control-next{
        display: none;
      }
      /* Top Menu */
      .menu-button{
        background-color: rgba(245, 222, 179, 0);
        border: none;
      }
      .menu-bar{
        position: absolute;
        display: none;
        width: 100%;
        height: 100vh;
      }
      .menu-section{
        position: absolute;
        right:1%;
        top: 35px;
        width: max-content;
      }
      .menu-button-section{
        top: 5px;
        background-color: #002bff;
        padding: 10px;
        height: 250px;
        right: 30px;
        display: flex;
        width: 250px;
        justify-content: flex-end;
        flex-direction: column;
      }
      .nav-menu-button{
        margin-top: 10px;
      }
  /* Glow on hover effect on button  */
            .glow-on-hover {
                width: max-content;
                height: 30px;
                border: none;
                outline: none;
                color: #fff;
                cursor: pointer;
                z-index: 4;
                border-radius: 10px;
              }
              
            .glow-on-hover:before {
                content: '';
                background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
                position: absolute;
                top: -2px;
                left:-2px;
                background-size: 400%;
                z-index: 4;
                filter: blur(5px);
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                animation: glowing 20s linear infinite;
                opacity: 0;
                transition: opacity .3s ease-in-out;
                border-radius: 10px;
            }
            .glow-on-hover:active {
                color: #000
            }
            
            .glow-on-hover:active:after {
              background: transparent;
            }
            
            .glow-on-hover:hover:before {
                opacity: 1;
            }
            
            .glow-on-hover:after {
                z-index: 4;
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                border-radius: 10px;
            }
            
            @keyframes glowing {
              0% { background-position: 0 0; }
              50% { background-position: 400% 0; }
              100% { background-position: 0 0; }
            }
      footer { 
        position: relative;
        bottom: 0px;
        background-color: #0d1a26;
        width: 100%;
        display: flex;
        justify-content: center;
        color: rgb(10, 83, 146);
        height: 40px;

      }

      .imgCard{
        position: relative;
        background-color: #000;
      }

       /* LINKS TREE SECTION */
      .links-div{
        display: flex;
        justify-content: center;
      }
      .links-section{
        width: min-content;
        position: relative;
        top: 40px;
      }
      .link-images{
        width: 90px;
        height: 80px;
      }
      .link_buttons{
        width: 180px;
        height: min-content;
        position: relative;
        top: 25px;
        left: 10px;
        margin: 20px;
      }
      .links-btns-div{
        width: max-content;
        display: flex;

      }
      .topMenu-div{
        position: sticky;
        top: 0px;
        width: 100%;
        color: white;
        z-index: +6;
      }




/* Mobile Screens */
@media screen and (max-width: 759px) {
  
  .wrapper {
    height: 475vh;
    width: 100%;
    z-index: -6;
    position: absolute;
    top:-100px
  }
  /* Landing Page CSS */

  .landing-body{
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: visible;
  }
  .landing-section-1{
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    grid-gap: 20px;
    padding-top: 50px;
    width: 1035px;
  }
    .landing-section-2{
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    grid-gap: 20px;
    margin-top: 20px;
    width: 1035px;
  }
  .landing-section-3{
    width: 100%;
    display: grid;
    grid-template-columns: 3fr;
    display: flex;
    justify-content: center;
    grid-gap: 40px;
    margin-top: 50px;
    width: 1035px;
  }
  .landing-section-4{
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 40px;
    margin-top: 75px;
    width: 1035px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    ;
  }
  .landing-section-5{
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 40px;
    display: flex;
    justify-content: center;
    margin-top: 75px;
    width: 315px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    ;
  }
  .landing-section-6{
    width: 100%;
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 40px;
    margin-top: 50px;
    width: 100%;
  }


/* Profile Card */
  .card-container{
    width: 315px;
  }
  .profileImgContainer{
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50% ;
    position: relative;
    left: 82.5px;
    top: 5px;
  }
  #profileImg{
    width: 100%;
    background-color: rgb(68 72 152 / 19%);
  }
  .contact-me-button{
    width: 90%;
    position: relative;
    left: 5%;
  }
  .contact-links-section{
    margin-top: 5px ;
  }
  .contact-link-a-tag{
    margin-right: 1%;
    margin-left: 1%;
  }
  .contact-link-icon{
    width:18%
  }

/* Contact Form */
  .contact-form{
    display: none;
    position: absolute;
    padding: 1%;
    width: 100%;
    top: 0px;
    height: 100%;
    z-index: 6;
  }
.contact-form-buttons{
  margin-left: 10px;
}

/* AI Imgs */
  .top-imgs-container{
    width: 315px;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    /* background-color: #1155cc; */
    position: relative;
    overflow-y: hidden;
  }
  .top-imgs-header{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  
  .openai_svgs{
    display: flex;
    justify-content: center;
    border-bottom: solid 2px #0d1a26;
  }
  .openai-text{
    display: flex;
    justify-content: center;
  }
  .openAi-logo{
    margin-left: 45px;
    margin-right: 45px;
    padding-top: 7px;
    padding-bottom: 7px;
    width: 95px;
  }
  .remove-img{
    display:none;
  }
  .top-imgs-buttons{
    width: 100%;
  }
  .top-imgs{
    width: 100%;
    height: 140px;
  }
  .top-imgs-actual-button{
    background-color: black;
    width: 50%;
  }
  .imgCard{
    position: absolute;
    background-color: #000;
    top: 45px;
    height: 300px;
    width: 100%;
    overflow-y: hidden;
  }
  .img-card-actual-image{
    width: 50%;
    position: relative;
    top: 10px;
    left: 45%;
  }
  .img-card-button{
    position: absolute;
    padding: 10px;
    bottom: 5px;
  }
  .img-card-button-1{
    left: 5%;

  }
  .img-card-button-2{
    width: 100px;
    left:53%;
  }

  .img-card-text{
    position: absolute;
    padding: 1%;
    top: 180px;
    left: 3%;
  }
  .img-card-title{
    position: absolute;
    top: 130px;
    left: 3%;
  }

/* About Me Page CSS */

  .about-me-container{
    width: 325px;
  }

  #about-me-text{
    text-indent: 20px;
    padding:5px;
  }


/* Projects Section */
.projects-container{
  position: relative;
  height: fit-content;
  overflow-y: hidden;
}
.project-link{
width:25%;
position: relative;
top: -10px;
}
.project-button{
width: 94%;
position: relative;
left: 3%;
top: 0px;
margin-bottom: 5px;
}
.project-button-git{
  position: relative;
  top: 0px;
}
.project-button-wp{
width: 94%;
position: relative;
left:3%;
top: 0px;
}









/* Resume Google Doc SRC */
      @import url('https://themes.googleusercontent.com/fonts/css?kit=RFda8w1V0eDZheqfcyQ4EGb3DKsRMD34dqg1gT8Z-p6isjtAVhoKeKPV_uAAgBOSk3k702ZOKiLJc3WVjuplzDj3qlxvkMVt2Q_AbZj4pHw');

      .lst-kix_d52h1clnbs3c-5>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_d52h1clnbs3c-3>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_d52h1clnbs3c-2>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_d52h1clnbs3c-6>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_d52h1clnbs3c-1>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_d52h1clnbs3c-7>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_d52h1clnbs3c-0>li:before {
        content: "\00200b  "
      }
  
      .lst-kix_d52h1clnbs3c-8>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_9ys133z7aaj4-8>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_9ys133z7aaj4-5>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_9ys133z7aaj4-7>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_9ys133z7aaj4-2>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_9ys133z7aaj4-6>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_9ys133z7aaj4-3>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_d52h1clnbs3c-4>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_9ys133z7aaj4-4>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_9ys133z7aaj4-1>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_9ys133z7aaj4-0>li:before {
        content: "\0025cf  "
      }
  
      ul.lst-kix_3expns8knkd9-2 {
        list-style-type: none
      }
  
      ul.lst-kix_3expns8knkd9-1 {
        list-style-type: none
      }
  
      ul.lst-kix_3expns8knkd9-4 {
        list-style-type: none
      }
  
      ul.lst-kix_3expns8knkd9-3 {
        list-style-type: none
      }
  
      ul.lst-kix_3expns8knkd9-0 {
        list-style-type: none
      }
  
      ul.lst-kix_3expns8knkd9-6 {
        list-style-type: none
      }
  
      ul.lst-kix_3expns8knkd9-5 {
        list-style-type: none
      }
  
      ul.lst-kix_3expns8knkd9-8 {
        list-style-type: none
      }
  
      ul.lst-kix_3expns8knkd9-7 {
        list-style-type: none
      }
  
      .lst-kix_3expns8knkd9-0>li:before {
        content: "\00200b  "
      }
  
      ul.lst-kix_9ys133z7aaj4-7 {
        list-style-type: none
      }
  
      ul.lst-kix_9ys133z7aaj4-6 {
        list-style-type: none
      }
  
      .lst-kix_3expns8knkd9-1>li:before {
        content: "\0025cb  "
      }
  
      ul.lst-kix_9ys133z7aaj4-5 {
        list-style-type: none
      }
  
      ul.lst-kix_9ys133z7aaj4-4 {
        list-style-type: none
      }
  
      ul.lst-kix_9ys133z7aaj4-8 {
        list-style-type: none
      }
  
      .lst-kix_3expns8knkd9-3>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_3expns8knkd9-2>li:before {
        content: "\0025a0  "
      }
  
      ul.lst-kix_9ys133z7aaj4-3 {
        list-style-type: none
      }
  
      ul.lst-kix_9ys133z7aaj4-2 {
        list-style-type: none
      }
  
      ul.lst-kix_9ys133z7aaj4-1 {
        list-style-type: none
      }
  
      ul.lst-kix_9ys133z7aaj4-0 {
        list-style-type: none
      }
  
      .lst-kix_3expns8knkd9-8>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_droieg8u3yfd-8>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_3expns8knkd9-7>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_3expns8knkd9-4>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_3expns8knkd9-5>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_3expns8knkd9-6>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_5u6m5vxe6pl5-2>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_5u6m5vxe6pl5-3>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_5u6m5vxe6pl5-0>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_5u6m5vxe6pl5-8>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_5u6m5vxe6pl5-1>li:before {
        content: "\0025cb  "
      }
  
      ul.lst-kix_d52h1clnbs3c-5 {
        list-style-type: none
      }
  
      .lst-kix_droieg8u3yfd-5>li:before {
        content: "\0025a0  "
      }
  
      ul.lst-kix_d52h1clnbs3c-6 {
        list-style-type: none
      }
  
      ul.lst-kix_d52h1clnbs3c-7 {
        list-style-type: none
      }
  
      ul.lst-kix_d52h1clnbs3c-8 {
        list-style-type: none
      }
  
      .lst-kix_f8hj6reyefk7-0>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_droieg8u3yfd-6>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_droieg8u3yfd-7>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_f8hj6reyefk7-2>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_f8hj6reyefk7-1>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_f8hj6reyefk7-3>li:before {
        content: "\0025cf  "
      }
  
      ul.lst-kix_d52h1clnbs3c-0 {
        list-style-type: none
      }
  
      ul.lst-kix_d52h1clnbs3c-1 {
        list-style-type: none
      }
  
      ul.lst-kix_d52h1clnbs3c-2 {
        list-style-type: none
      }
  
      ul.lst-kix_d52h1clnbs3c-3 {
        list-style-type: none
      }
  
      ul.lst-kix_d52h1clnbs3c-4 {
        list-style-type: none
      }
  
      .lst-kix_f8hj6reyefk7-6>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_droieg8u3yfd-4>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_f8hj6reyefk7-5>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_f8hj6reyefk7-4>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_droieg8u3yfd-2>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_droieg8u3yfd-3>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_droieg8u3yfd-0>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_droieg8u3yfd-1>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_5u6m5vxe6pl5-7>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_5u6m5vxe6pl5-6>li:before {
        content: "\0025cf  "
      }
  
      .lst-kix_f8hj6reyefk7-7>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_5u6m5vxe6pl5-4>li:before {
        content: "\0025cb  "
      }
  
      .lst-kix_f8hj6reyefk7-8>li:before {
        content: "\0025a0  "
      }
  
      .lst-kix_5u6m5vxe6pl5-5>li:before {
        content: "\0025a0  "
      }
  
      ul.lst-kix_5u6m5vxe6pl5-2 {
        list-style-type: none
      }
  
      ul.lst-kix_5u6m5vxe6pl5-1 {
        list-style-type: none
      }
  
      ul.lst-kix_5u6m5vxe6pl5-4 {
        list-style-type: none
      }
  
      ul.lst-kix_5u6m5vxe6pl5-3 {
        list-style-type: none
      }
  
      ul.lst-kix_5u6m5vxe6pl5-6 {
        list-style-type: none
      }
  
      ul.lst-kix_5u6m5vxe6pl5-5 {
        list-style-type: none
      }
  
      ul.lst-kix_5u6m5vxe6pl5-8 {
        list-style-type: none
      }
  
      ul.lst-kix_5u6m5vxe6pl5-7 {
        list-style-type: none
      }
  
      ul.lst-kix_5u6m5vxe6pl5-0 {
        list-style-type: none
      }
  
      ul.lst-kix_f8hj6reyefk7-8 {
        list-style-type: none
      }
  
      ul.lst-kix_f8hj6reyefk7-5 {
        list-style-type: none
      }
  
      ul.lst-kix_f8hj6reyefk7-4 {
        list-style-type: none
      }
  
      ul.lst-kix_f8hj6reyefk7-7 {
        list-style-type: none
      }
  
      ul.lst-kix_f8hj6reyefk7-6 {
        list-style-type: none
      }
  
      ul.lst-kix_f8hj6reyefk7-1 {
        list-style-type: none
      }
  
      ul.lst-kix_f8hj6reyefk7-0 {
        list-style-type: none
      }
  
      ul.lst-kix_f8hj6reyefk7-3 {
        list-style-type: none
      }
  
      ul.lst-kix_f8hj6reyefk7-2 {
        list-style-type: none
      }
  
      ul.lst-kix_droieg8u3yfd-3 {
        list-style-type: none
      }
  
      ul.lst-kix_droieg8u3yfd-2 {
        list-style-type: none
      }
  
      ul.lst-kix_droieg8u3yfd-5 {
        list-style-type: none
      }
  
      ul.lst-kix_droieg8u3yfd-4 {
        list-style-type: none
      }
  
      ul.lst-kix_droieg8u3yfd-1 {
        list-style-type: none
      }
  
      ul.lst-kix_droieg8u3yfd-0 {
        list-style-type: none
      }
  
      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
      }
  
      ul.lst-kix_droieg8u3yfd-7 {
        list-style-type: none
      }
  
      ul.lst-kix_droieg8u3yfd-6 {
        list-style-type: none
      }
  
      ul.lst-kix_droieg8u3yfd-8 {
        list-style-type: none
      }
  
      ol {
        margin: 0;
        padding: 0
      }
  
      table td,
      table th {
        padding: 0
      }
  
      .c10 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-right-width: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        border-top-style: solid;
        background-color: #ffffff;
        margin-left: 36pt;
        border-bottom-width: 0pt;
        border-bottom-style: solid;
        text-align: left;
        padding-right: 0pt
      }
  
      .c0 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-right-width: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        border-top-style: solid;
        margin-left: 108pt;
        border-bottom-width: 0pt;
        border-bottom-style: solid;
        text-align: left;
        padding-right: 0pt
      }
  
      .c50 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-right-width: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        border-top-style: solid;
        margin-left: 36pt;
        border-bottom-width: 0pt;
        border-bottom-style: solid;
        text-align: left;
        padding-right: 0pt
      }
  
      .c4 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-right-width: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        border-top-style: solid;
        margin-left: 72pt;
        border-bottom-width: 0pt;
        border-bottom-style: solid;
        text-align: left;
        padding-right: 0pt
      }
  
      .c29 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-right-width: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        border-top-style: solid;
        margin-left: 144pt;
        border-bottom-width: 0pt;
        border-bottom-style: solid;
        text-align: left;
        padding-right: 0pt
      }
  
      .c35 {
        border-right-style: solid;
        padding: 7.2pt 7.2pt 7.2pt 7.2pt;
        border-bottom-color: #ffffff;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #ffffff;
        vertical-align: top;
        border-right-color: #ffffff;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 358.5pt;
        border-top-color: #ffffff;
        border-bottom-style: solid
      }
  
      .c3 {
        border-right-style: solid;
        padding: 7.2pt 7.2pt 7.2pt 7.2pt;
        border-bottom-color: #ffffff;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #ffffff;
        vertical-align: top;
        border-right-color: #ffffff;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 165pt;
        border-top-color: #ffffff;
        border-bottom-style: solid
      }
  
      .c28 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left;
        margin-right: 15pt
      }
  
      .c33 {
        color: #2079c7;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 19pt;
        font-family: "Open Sans";
        font-style: normal
      }
  
      .c13 {
        color: #666666;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Merriweather";
        font-style: normal
      }
  
      .c25 {
        color: #212529;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 7.5pt;
        font-family: "Merriweather";
        font-style: normal
      }
  
      .c32 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Open Sans";
        font-style: normal
      }
  
      .c45 {
        color: #666666;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 8pt;
        font-family: "Open Sans";
        font-style: normal
      }
  
      .c20 {
        color: #666666;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 7pt;
        font-family: "Open Sans";
        font-style: normal
      }
  
      .c18 {
        color: #2079c7;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Open Sans";
        font-style: normal
      }
  
      .c19 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 7.5pt;
        font-family: "Merriweather";
        font-style: normal
      }
  
      .c43 {
        margin-left: 72pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left;
        margin-right: 15pt
      }
  
      .c5 {
        color: #212529;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 7.5pt;
        font-family: "Roboto";
        font-style: normal
      }
  
      .c16 {
        color: #666666;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 8pt;
        font-family: "Merriweather";
        font-style: normal
      }
  
      .c14 {
        margin-left: 36pt;
        padding-top: 6pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.3;
        text-align: left;
        margin-right: 15pt
      }
  
      .c6 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 7.5pt;
        font-family: "Merriweather";
        font-style: normal
      }
  
      .c24 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 19pt;
        font-family: "Times New Roman";
        font-style: normal
      }
  
      .c2 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Open Sans";
        font-style: normal
      }
  
      .c22 {
        color: #666666;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Merriweather";
        font-style: normal
      }
  
      .c12 {
        margin-left: 108pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left;
        margin-right: 15pt
      }
  
      .c9 {
        color: #666666;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 8pt;
        font-family: "Merriweather";
        font-style: normal
      }
  
      .c8 {
        color: #212529;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 7.5pt;
        font-family: "Merriweather";
        font-style: normal
      }
  
      .c41 {
        color: #666666;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 6pt;
        font-family: "Merriweather";
        font-style: normal
      }
  
      .c40 {
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        font-weight: 700;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        font-family: "Open Sans"
      }
  
      .c30 {
        padding-top: 16pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        page-break-after: avoid;
        text-align: left;
        margin-right: 15pt
      }
  
      .c31 {
        padding-top: 5pt;
        padding-bottom: 5pt;
        line-height: 1.0;
        page-break-after: avoid;
        text-align: left;
        margin-right: 15pt
      }
  
      .c44 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-family: "Merriweather";
        font-style: normal
      }
  
      .c39 {
        padding-top: 0pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        text-align: left;
        margin-right: 15pt
      }
  
      .c21 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        text-align: left;
        margin-right: 15pt
      }
  
      .c51 {
        color: #666666;
        text-decoration: none;
        vertical-align: baseline;
        font-family: "Open Sans";
        font-style: normal
      }
  
      .c36 {
        padding-top: 6pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left;
        margin-right: 15pt
      }
  
      .c53 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left;
        margin-right: 15pt
      }
  
      .c27 {
        padding-top: 6pt;
        padding-bottom: 0pt;
        line-height: 1.3;
        text-align: left;
        margin-right: 15pt
      }
  
      .c42 {
        padding-top: 16pt;
        padding-bottom: 0pt;
        line-height: 1.3;
        text-align: left;
        margin-right: 15pt
      }
  
      .c38 {
        padding-top: 30pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left;
        margin-right: 15pt
      }
  
      .c17 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
      }
  
      .c34 {
        font-size: 7pt;
        font-family: "Open Sans";
        color: #666666;
        font-weight: 400
      }
  
      .c47 {
        color: #212529;
        font-size: 7.5pt
      }
  
      .c11 {
        color: inherit;
        text-decoration: inherit
      }
  
      .c7 {
        padding: 0;
        margin: 0
      }
  
      .c46 {
        font-size: 8pt;
        font-weight: 700
      }
  
      .c52 {
        max-width: 2025.6pt;
        padding: 24.8pt 22.2pt 40.2pt 22.2pt
      }
  
      .c1 {
        font-size: 10pt;
        font-weight: 400
      }
  
      .c26 {
        font-size: 10pt
      }
  
      .c15 {
        width: min-content;
        background-color: #ffffff
      }
  
      .c48 {
        height: 588pt
      }
  
      .c37 {
        font-size: 7pt
      }
  
      .c23 {
        height: 9pt
      }
  
      .c49 {
        height: 4.5pt
      }
  
      .title {
        padding-top: 0pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Merriweather";
        line-height: 1.0;
        text-align: left
      }
  
      .subtitle {
        padding-top: 0pt;
        color: #000000;
        font-size: 9pt;
        padding-bottom: 0pt;
        font-family: "Open Sans";
        line-height: 1.15;
        text-align: left
      }
  
      li {
        color: #666666;
        font-size: 9pt;
        font-family: "Merriweather"
      }
  
      p {
        margin: 0;
        font-size: 9pt;
        font-family: "Merriweather"
      }
  
      h1 {
        padding-top: 30pt;
        font-weight: 700;
        font-size: 9pt;
        padding-bottom: 0pt;
        font-family: "Open Sans";
        line-height: 1.0;
        text-align: left
      }
  
      h2 {
        padding-top: 16pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 0pt;
        font-family: "Merriweather";
        line-height: 1.0;
        page-break-after: avoid;
        text-align: left
      }
  
      h3 {
        padding-top: 5pt;
        color: #666666;
        font-size: 8pt;
        padding-bottom: 5pt;
        font-family: "Open Sans";
        line-height: 1.0;
        page-break-after: avoid;
        text-align: left
      }
  
      h4 {
        padding-top: 8pt;
        -webkit-text-decoration-skip: none;
        color: #666666;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        font-size: 11pt;
        padding-bottom: 0pt;
        font-family: "Trebuchet MS";
        line-height: 1.3;
        page-break-after: avoid;
        text-align: left
      }
  
      h5 {
        padding-top: 8pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 0pt;
        font-family: "Trebuchet MS";
        line-height: 1.3;
        page-break-after: avoid;
        text-align: left
      }
  
      h6 {
        padding-top: 8pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 0pt;
        font-family: "Trebuchet MS";
        line-height: 1.3;
        page-break-after: avoid;
        font-style: italic;
        text-align: left
      }
      #resume{
        display: inline;
        width: fit-content;
        margin-right: 15px;
        margin-left: 15px;
        z-index: 3;
      }
      #resumeDownloadLink{
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        color: #1155cc;
        bottom: 15px;
      }
      




/* Public Form */

.public-form-container{
width: 90%;
padding-top: 1%;
padding-bottom: 1%;
margin-left: 5%;
}

.public-form{
width: 90%;
padding-top: 1%;
padding-bottom: 1%;
margin-left: 5%;

}
.openAi-logo-public{
margin-left: 45px;
margin-right: 45px;
padding-top: 7px;
padding-bottom: 7px;
width: 150px;
}

/* User Ai Generator Agreement */
  #agreementSection{
    position: absolute;
    background-color: beige;
    padding: 2%;
    width: 93.5%;
    height: min-content;
  }
/* User Generated Imgs */
.user-imgs{
  width: 100%;
}
.user-imgs-actual-button{
  background-color: #000;
  width: 100%;
}




  
}

/* Tablet Screens */
@media screen and (min-width: 760px){


  /* Landing Page CSS */

  .landing-body{
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: visible;
  }
  .landing-section-1{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    padding-top: 75px;
    width: 1035px;
  }
    .landing-section-2{
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    grid-gap: 20px;
    margin-top: 20px;
    width: 1035px;
  }
  .landing-section-3{
    width: 100%;
    display: grid;
    grid-template-columns: 3fr;
    display: flex;
    justify-content: center;
    grid-gap: 40px;
    margin-top: 50px;
    width: 1035px;
  }
  .landing-section-4{
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 40px;
    margin-top: 75px;
    width: 1035px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    ;
  }
  .landing-section-5{
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 40px;
    display: flex;
    justify-content: center;
    margin-top: 75px;
    width: 650px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    ;
  }
  .landing-section-6{
    width: 100%;
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 40px;
    margin-top: 50px;
    width: 100%;
  }

/* Profile Card */
  .card-container{
    width: 315px;
  }
  .profileImgContainer{
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50% ;
    position: relative;
    left: 82.5px;
    top: 5px;
  }
  #profileImg{
    width: 100%;
    background-color: rgb(68 72 152 / 19%);
  }
  .contact-me-button{
    width: 90%;
    position: relative;
    left: 5%;
  }
  .contact-links-section{
    margin-top: 5px ;
  }
  .contact-link-a-tag{
    margin-right: 1%;
    margin-left: 1%;
  }
  .contact-link-icon{
    width:18%
  }

    /* Contact Form */
  .contact-form{
    display: none;
    position: absolute;
    padding: 1%;
    width: 100%;
    top: 0px;
    height: 100%;
    z-index: 6;
  }
.contact-form-buttons{
  margin-left: 10px;
}

/* AI Imgs */
  .top-imgs-container{
    width: 315px;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow-y: hidden;
  }
  .top-imgs-header{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  
  .openai_svgs{
    display: flex;
    justify-content: center;
    border-bottom: solid 2px #0d1a26;
  }
  .openai-text{
    display: flex;
    justify-content: center;
  }
  .openAi-logo{
    margin-left: 45px;
    margin-right: 45px;
    padding-top: 7px;
    padding-bottom: 7px;
    width: 95px;
  }
  .remove-img{
    display:none;
  }
  .top-imgs-buttons{
    width: 100%;
  }
  .top-imgs{
    width: 100%;
    height: 140px;
  }
  .top-imgs-actual-button{
    background-color: #000000;
    width: 50%;
  }
  .imgCard{
    position: absolute;
    background-color: #000;
    top: 45px;
    height: 300px;
    width: 100%;
    overflow-y: hidden;
  }
  .img-card-actual-image{
    width: 50%;
    position: relative;
    top: 10px;
    left: 45%;
  }
  .img-card-button{
    position: absolute;
    padding: 10px;
    bottom: 5px;
  }
  .img-card-button-1{
    left: 5%;

  }
  .img-card-button-2{
    width: 100px;
    left:53%;
  }

  .img-card-text{
    position: absolute;
    top: 180px;
    left: 3%;
  }
  .img-card-title{
    position: absolute;
    top: 130px;
    left: 3%;
  }

/* About Me Page CSS */

  .about-me-container{
    width: 650px;
  }

  #about-me-text{
    text-indent: 20px;
    padding:5px;
  }


/* Projects Section */
.projects-container{
  position: relative;
  left: 162.5px
}
.project-link{
width:25%;
position: relative;
top: -10px;
}
.project-button{
width: 94%;
position: relative;
left: 3%;
top: -10px;
margin-bottom: 10px;
}
.project-button-git{
  position: relative;
  top: 10px;
}
.project-button-wp{
width: 94%;
position: relative;
left:3%;
top: 20px;
}

.projects-body{
  padding-top:25%;
}








/* RESUME */
@import url('https://themes.googleusercontent.com/fonts/css?kit=RFda8w1V0eDZheqfcyQ4EGb3DKsRMD34dqg1gT8Z-p6isjtAVhoKeKPV_uAAgBOSk3k702ZOKiLJc3WVjuplzDj3qlxvkMVt2Q_AbZj4pHw');

.lst-kix_d52h1clnbs3c-5>li:before {
content: "\0025a0  "
}

.lst-kix_d52h1clnbs3c-3>li:before {
content: "\0025cf  "
}

.lst-kix_d52h1clnbs3c-2>li:before {
content: "\0025a0  "
}

.lst-kix_d52h1clnbs3c-6>li:before {
content: "\0025cf  "
}

.lst-kix_d52h1clnbs3c-1>li:before {
content: "\0025cb  "
}

.lst-kix_d52h1clnbs3c-7>li:before {
content: "\0025cb  "
}

.lst-kix_d52h1clnbs3c-0>li:before {
content: "\00200b  "
}

.lst-kix_d52h1clnbs3c-8>li:before {
content: "\0025a0  "
}

.lst-kix_9ys133z7aaj4-8>li:before {
content: "\0025a0  "
}

.lst-kix_9ys133z7aaj4-5>li:before {
content: "\0025a0  "
}

.lst-kix_9ys133z7aaj4-7>li:before {
content: "\0025cb  "
}

.lst-kix_9ys133z7aaj4-2>li:before {
content: "\0025a0  "
}

.lst-kix_9ys133z7aaj4-6>li:before {
content: "\0025cf  "
}

.lst-kix_9ys133z7aaj4-3>li:before {
content: "\0025cf  "
}

.lst-kix_d52h1clnbs3c-4>li:before {
content: "\0025cb  "
}

.lst-kix_9ys133z7aaj4-4>li:before {
content: "\0025cb  "
}

.lst-kix_9ys133z7aaj4-1>li:before {
content: "\0025cb  "
}

.lst-kix_9ys133z7aaj4-0>li:before {
content: "\0025cf  "
}

ul.lst-kix_3expns8knkd9-2 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-1 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-4 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-3 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-0 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-6 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-5 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-8 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-7 {
list-style-type: none
}

.lst-kix_3expns8knkd9-0>li:before {
content: "\00200b  "
}

ul.lst-kix_9ys133z7aaj4-7 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-6 {
list-style-type: none
}

.lst-kix_3expns8knkd9-1>li:before {
content: "\0025cb  "
}

ul.lst-kix_9ys133z7aaj4-5 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-4 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-8 {
list-style-type: none
}

.lst-kix_3expns8knkd9-3>li:before {
content: "\0025cf  "
}

.lst-kix_3expns8knkd9-2>li:before {
content: "\0025a0  "
}

ul.lst-kix_9ys133z7aaj4-3 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-2 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-1 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-0 {
list-style-type: none
}

.lst-kix_3expns8knkd9-8>li:before {
content: "\0025a0  "
}

.lst-kix_droieg8u3yfd-8>li:before {
content: "\0025a0  "
}

.lst-kix_3expns8knkd9-7>li:before {
content: "\0025cb  "
}

.lst-kix_3expns8knkd9-4>li:before {
content: "\0025cb  "
}

.lst-kix_3expns8knkd9-5>li:before {
content: "\0025a0  "
}

.lst-kix_3expns8knkd9-6>li:before {
content: "\0025cf  "
}

.lst-kix_5u6m5vxe6pl5-2>li:before {
content: "\0025a0  "
}

.lst-kix_5u6m5vxe6pl5-3>li:before {
content: "\0025cf  "
}

.lst-kix_5u6m5vxe6pl5-0>li:before {
content: "\0025cf  "
}

.lst-kix_5u6m5vxe6pl5-8>li:before {
content: "\0025a0  "
}

.lst-kix_5u6m5vxe6pl5-1>li:before {
content: "\0025cb  "
}

ul.lst-kix_d52h1clnbs3c-5 {
list-style-type: none
}

.lst-kix_droieg8u3yfd-5>li:before {
content: "\0025a0  "
}

ul.lst-kix_d52h1clnbs3c-6 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-7 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-8 {
list-style-type: none
}

.lst-kix_f8hj6reyefk7-0>li:before {
content: "\0025cf  "
}

.lst-kix_droieg8u3yfd-6>li:before {
content: "\0025cf  "
}

.lst-kix_droieg8u3yfd-7>li:before {
content: "\0025cb  "
}

.lst-kix_f8hj6reyefk7-2>li:before {
content: "\0025a0  "
}

.lst-kix_f8hj6reyefk7-1>li:before {
content: "\0025cb  "
}

.lst-kix_f8hj6reyefk7-3>li:before {
content: "\0025cf  "
}

ul.lst-kix_d52h1clnbs3c-0 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-1 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-2 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-3 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-4 {
list-style-type: none
}

.lst-kix_f8hj6reyefk7-6>li:before {
content: "\0025cf  "
}

.lst-kix_droieg8u3yfd-4>li:before {
content: "\0025cb  "
}

.lst-kix_f8hj6reyefk7-5>li:before {
content: "\0025a0  "
}

.lst-kix_f8hj6reyefk7-4>li:before {
content: "\0025cb  "
}

.lst-kix_droieg8u3yfd-2>li:before {
content: "\0025a0  "
}

.lst-kix_droieg8u3yfd-3>li:before {
content: "\0025cf  "
}

.lst-kix_droieg8u3yfd-0>li:before {
content: "\0025cf  "
}

.lst-kix_droieg8u3yfd-1>li:before {
content: "\0025cb  "
}

.lst-kix_5u6m5vxe6pl5-7>li:before {
content: "\0025cb  "
}

.lst-kix_5u6m5vxe6pl5-6>li:before {
content: "\0025cf  "
}

.lst-kix_f8hj6reyefk7-7>li:before {
content: "\0025cb  "
}

.lst-kix_5u6m5vxe6pl5-4>li:before {
content: "\0025cb  "
}

.lst-kix_f8hj6reyefk7-8>li:before {
content: "\0025a0  "
}

.lst-kix_5u6m5vxe6pl5-5>li:before {
content: "\0025a0  "
}

ul.lst-kix_5u6m5vxe6pl5-2 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-1 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-4 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-3 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-6 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-5 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-8 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-7 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-0 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-8 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-5 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-4 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-7 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-6 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-1 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-0 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-3 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-2 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-3 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-2 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-5 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-4 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-1 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-0 {
list-style-type: none
}

li.li-bullet-0:before {
margin-left: -18pt;
white-space: nowrap;
display: inline-block;
min-width: 18pt
}

ul.lst-kix_droieg8u3yfd-7 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-6 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-8 {
list-style-type: none
}

ol {
margin: 0;
padding: 0
}

table td,
table th {
padding: 0
}

.c10 {
border-right-style: solid;
padding-top: 0pt;
border-top-width: 0pt;
border-right-width: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
border-top-style: solid;
background-color: #ffffff;
margin-left: 36pt;
border-bottom-width: 0pt;
border-bottom-style: solid;
text-align: left;
padding-right: 0pt
}

.c0 {
border-right-style: solid;
padding-top: 0pt;
border-top-width: 0pt;
border-right-width: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
border-top-style: solid;
margin-left: 108pt;
border-bottom-width: 0pt;
border-bottom-style: solid;
text-align: left;
padding-right: 0pt
}

.c50 {
border-right-style: solid;
padding-top: 0pt;
border-top-width: 0pt;
border-right-width: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
border-top-style: solid;
margin-left: 36pt;
border-bottom-width: 0pt;
border-bottom-style: solid;
text-align: left;
padding-right: 0pt
}

.c4 {
border-right-style: solid;
padding-top: 0pt;
border-top-width: 0pt;
border-right-width: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
border-top-style: solid;
margin-left: 72pt;
border-bottom-width: 0pt;
border-bottom-style: solid;
text-align: left;
padding-right: 0pt
}

.c29 {
border-right-style: solid;
padding-top: 0pt;
border-top-width: 0pt;
border-right-width: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
border-top-style: solid;
margin-left: 144pt;
border-bottom-width: 0pt;
border-bottom-style: solid;
text-align: left;
padding-right: 0pt
}

.c35 {
border-right-style: solid;
padding: 7.2pt 7.2pt 7.2pt 7.2pt;
border-bottom-color: #ffffff;
border-top-width: 0pt;
border-right-width: 0pt;
border-left-color: #ffffff;
vertical-align: top;
border-right-color: #ffffff;
border-left-width: 0pt;
border-top-style: solid;
border-left-style: solid;
border-bottom-width: 0pt;
width: 358.5pt;
border-top-color: #ffffff;
border-bottom-style: solid
}

.c3 {
border-right-style: solid;
padding: 7.2pt 7.2pt 7.2pt 7.2pt;
border-bottom-color: #ffffff;
border-top-width: 0pt;
border-right-width: 0pt;
border-left-color: #ffffff;
vertical-align: top;
border-right-color: #ffffff;
border-left-width: 0pt;
border-top-style: solid;
border-left-style: solid;
border-bottom-width: 0pt;
width: 165pt;
border-top-color: #ffffff;
border-bottom-style: solid
}

.c28 {
margin-left: 36pt;
padding-top: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c33 {
color: #2079c7;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 19pt;
font-family: "Open Sans";
font-style: normal
}

.c13 {
color: #666666;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 9pt;
font-family: "Merriweather";
font-style: normal
}

.c25 {
color: #212529;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 7.5pt;
font-family: "Merriweather";
font-style: normal
}

.c32 {
color: #000000;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 9pt;
font-family: "Open Sans";
font-style: normal
}

.c45 {
color: #666666;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 8pt;
font-family: "Open Sans";
font-style: normal
}

.c20 {
color: #666666;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 7pt;
font-family: "Open Sans";
font-style: normal
}

.c18 {
color: #2079c7;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 9pt;
font-family: "Open Sans";
font-style: normal
}

.c19 {
color: #000000;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 7.5pt;
font-family: "Merriweather";
font-style: normal
}

.c43 {
margin-left: 72pt;
padding-top: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c5 {
color: #212529;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 7.5pt;
font-family: "Roboto";
font-style: normal
}

.c16 {
color: #666666;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 8pt;
font-family: "Merriweather";
font-style: normal
}

.c14 {
margin-left: 36pt;
padding-top: 6pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.3;
text-align: left;
margin-right: 15pt
}

.c6 {
color: #000000;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 7.5pt;
font-family: "Merriweather";
font-style: normal
}

.c24 {
color: #000000;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 24pt;
font-family: "Times New Roman";
font-style: normal
}

.c2 {
color: #000000;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 9pt;
font-family: "Open Sans";
font-style: normal
}

.c22 {
color: #666666;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 9pt;
font-family: "Merriweather";
font-style: normal
}

.c12 {
margin-left: 108pt;
padding-top: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c9 {
color: #666666;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 8pt;
font-family: "Merriweather";
font-style: normal
}

.c8 {
color: #212529;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 7.5pt;
font-family: "Merriweather";
font-style: normal
}

.c41 {
color: #666666;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 6pt;
font-family: "Merriweather";
font-style: normal
}

.c40 {
-webkit-text-decoration-skip: none;
color: #1155cc;
font-weight: 700;
text-decoration: underline;
text-decoration-skip-ink: none;
font-family: "Open Sans"
}

.c30 {
padding-top: 16pt;
padding-bottom: 0pt;
line-height: 1.0;
page-break-after: avoid;
text-align: left;
margin-right: 15pt
}

.c31 {
padding-top: 5pt;
padding-bottom: 5pt;
line-height: 1.0;
page-break-after: avoid;
text-align: left;
margin-right: 15pt
}

.c44 {
color: #000000;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-family: "Merriweather";
font-style: normal
}

.c39 {
padding-top: 0pt;
padding-bottom: 6pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c21 {
padding-top: 0pt;
padding-bottom: 0pt;
line-height: 1.15;
text-align: left;
margin-right: 15pt
}

.c51 {
color: #666666;
text-decoration: none;
vertical-align: baseline;
font-family: "Open Sans";
font-style: normal
}

.c36 {
padding-top: 6pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c53 {
padding-top: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c27 {
padding-top: 6pt;
padding-bottom: 0pt;
line-height: 1.3;
text-align: left;
margin-right: 15pt
}

.c42 {
padding-top: 16pt;
padding-bottom: 0pt;
line-height: 1.3;
text-align: left;
margin-right: 15pt
}

.c38 {
padding-top: 30pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c17 {
border-spacing: 0;
border-collapse: collapse;
margin-right: auto
}

.c34 {
font-size: 7pt;
font-family: "Open Sans";
color: #666666;
font-weight: 400
}

.c47 {
color: #212529;
font-size: 7.5pt
}

.c11 {
color: inherit;
text-decoration: inherit
}

.c7 {
padding: 0;
margin: 0
}

.c46 {
font-size: 8pt;
font-weight: 700
}

.c52 {
max-width: 2025.6pt;
padding: 28.8pt 43.2pt 43.2pt 43.2pt
}

.c1 {
font-size: 10pt;
font-weight: 400
}

.c26 {
font-size: 10pt
}

.c15 {
width: 100%;
background-color: #ffffff
}

.c48 {
height: 588pt
}

.c37 {
font-size: 7pt
}

.c23 {
height: 9pt
}

.c49 {
height: 4.5pt
}

.title {
padding-top: 0pt;
color: #000000;
font-weight: 700;
font-size: 36pt;
padding-bottom: 6pt;
font-family: "Merriweather";
line-height: 1.0;
text-align: left
}

.subtitle {
padding-top: 0pt;
color: #000000;
font-size: 9pt;
padding-bottom: 0pt;
font-family: "Open Sans";
line-height: 1.15;
text-align: left
}

li {
color: #666666;
font-size: 9pt;
font-family: "Merriweather"
}

p {
margin: 0;
}

h1 {
padding-top: 30pt;
font-weight: 700;
font-size: 9pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left
}

h2 {
padding-top: 16pt;
color: #000000;
font-weight: 700;
font-size: 11pt;
padding-bottom: 0pt;
font-family: "Merriweather";
line-height: 1.0;
page-break-after: avoid;
text-align: left
}

h3 {
padding-top: 5pt;
color: #666666;
font-size: 8pt;
padding-bottom: 5pt;
font-family: "Open Sans";
line-height: 1.0;
page-break-after: avoid;
text-align: left
}

h4 {
padding-top: 8pt;
-webkit-text-decoration-skip: none;
color: #666666;
text-decoration: underline;
text-decoration-skip-ink: none;
font-size: 11pt;
padding-bottom: 0pt;
font-family: "Trebuchet MS";
line-height: 1.3;
page-break-after: avoid;
text-align: left
}

h5 {
padding-top: 8pt;
color: #666666;
font-size: 11pt;
padding-bottom: 0pt;
font-family: "Trebuchet MS";
line-height: 1.3;
page-break-after: avoid;
text-align: left
}

h6 {
padding-top: 8pt;
color: #666666;
font-size: 11pt;
padding-bottom: 0pt;
font-family: "Trebuchet MS";
line-height: 1.3;
page-break-after: avoid;
font-style: italic;
text-align: left
}
#resume{
background-color: #000;
position: relative;
width: 650px;
display: flex;
justify-content: center;
padding: 1.5px;
margin-top:10px ;
display: inline;
z-index: 3;

}
#resumeDownloadLink{
display: flex;
justify-content: center;
position: absolute;
width: 100%;
color: #1155cc;
top: 500px;
}







/* Public Form */

.public-form-container{
width: 90%;
padding-top: 1%;
padding-bottom: 1%;
margin-left: 5%;
}

.public-form{
width: 90%;
padding-top: 1%;
padding-bottom: 1%;
margin-left: 5%;

}
.openAi-logo-public{
margin-left: 45px;
margin-right: 45px;
padding-top: 7px;
padding-bottom: 7px;
width: 150px;
}
/* User Ai Generator Agreement */
  #agreementSection{
    position: absolute;
    background-color: beige;
    padding: 2%;
    width: 93.5%;
    height: min-content;
  }
/* User Generated Imgs */
.user-imgs{
  width: 100%;
}
.user-imgs-actual-button{
  background-color: #000000;

  width: 100%;
}




}



/* Desktop Screen */
@media screen and (min-width: 1165px){

  /* Landing Page CSS */

  .landing-body{
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: visible;
  }
  .landing-section-1{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 40px;
    padding-top: 75px;
    width: 1035px;
  }
    .landing-section-2{
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
    margin-top: 50px;
    width: 1035px;
  }
  .landing-section-3{
    width: 100%;
    display: flex;
    margin-top: 50px;
    width: 1035px;
  }
  .landing-section-4{
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 40px;
    margin-top: 75px;
    width: 1035px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    ;
  }
  .landing-section-5{
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 40px;
    margin-top: 105px;
    width: 1035px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    ;
  }
  .landing-section-6{
    width: 100%;
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 40px;
    margin-top: 50px;
    width: 100%;
  }


/* Profile Card */
  .card-container{
    width: 315px;
  }
  .profileImgContainer{
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50% ;
    position: relative;
    left: 82.5px;
    top: 5px;
  }
  #profileImg{
    width: 100%;
    background-color: rgb(68 72 152 / 19%);
  }
  .contact-me-button{
    width: 90%;
    position: relative;
    left: 5%;
  }
  .contact-links-section{
    margin-top: 15px ;
  }
  .contact-link-a-tag{
    margin-right: 1%;
    margin-left: 1%;
  }
  .contact-link-icon{
    width:18%
  }

  /* Contact Form */
  .contact-form{
    display: none;
    position: absolute;
    padding: 1%;
    width: 100%;
    top: 0px;
    height: 100%;
    z-index: 6;
  }

/* AI Imgs */
  .top-imgs-container{
    width: 660px;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow-y: hidden;
  }
  .top-imgs-header{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  
  .openai_svgs{
    display: flex;
    justify-content: center;
    border-bottom: solid 2px #0d1a26;
  }
  .openai-text{
    display: flex;
    justify-content: center;
  }
  .openAi-logo{
    margin-left: 45px;
    margin-right: 45px;
    padding-top: 7px;
    padding-bottom: 7px;
    width: 95px;
  }
  .top-imgs-buttons{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    /* width: 100%; */
  }
  .remove-img{
    display:block;
  }
  .top-imgs{
    width:100%;
  }
  .top-imgs-actual-button{
    width: 100%;
  }
  .imgCard{
    position: absolute;
    background-color: #000;
    top: 0px;
    height: 400px;
    width: 100%;
    display: flex;  
    flex-direction: row;
    overflow-y: hidden;
  }
  .img-card-actual-image{
    position: relative;
    top: 10px;
    left: 5px;
    width: 50%;
  }
  .img-card-button{
    position: absolute;
    padding: 10px;
    bottom: 55px;
  }
  .img-card-button-1{
    left: 51%;

  }
  .img-card-button-2{
    width: 100px;
    left:83%;
  }

  .img-card-text{
    position: absolute;
    top: 150px;
    left: 53%;
  }
  .img-card-title{
    position: absolute;
    top: 50px;
    left: 53%;
  }






/* About Me Page CSS */
  #about-me-text{
    text-indent: 20px;
    padding:5px;
  }


/* Projects Section */
.projects-container{
  position: relative;
  left: 0px
}
.project-link{
width:25%;
position: relative;
top: -10px;
}
.project-button{
width: 94%;
position: relative;
left: 3%;
top: 0px;
margin-bottom: 10px;
}
.project-button-git{
  position: relative;
  top: 10px;
}
.project-button-wp{
width: 94%;
position: relative;
left:3%;
top: 20px;
}

.projects-body{
  padding-top:20%;
}









/* RESUME */
@import url('https://themes.googleusercontent.com/fonts/css?kit=RFda8w1V0eDZheqfcyQ4EGb3DKsRMD34dqg1gT8Z-p6isjtAVhoKeKPV_uAAgBOSk3k702ZOKiLJc3WVjuplzDj3qlxvkMVt2Q_AbZj4pHw');

.lst-kix_d52h1clnbs3c-5>li:before {
content: "\0025a0  "
}

.lst-kix_d52h1clnbs3c-3>li:before {
content: "\0025cf  "
}

.lst-kix_d52h1clnbs3c-2>li:before {
content: "\0025a0  "
}

.lst-kix_d52h1clnbs3c-6>li:before {
content: "\0025cf  "
}

.lst-kix_d52h1clnbs3c-1>li:before {
content: "\0025cb  "
}

.lst-kix_d52h1clnbs3c-7>li:before {
content: "\0025cb  "
}

.lst-kix_d52h1clnbs3c-0>li:before {
content: "\00200b  "
}

.lst-kix_d52h1clnbs3c-8>li:before {
content: "\0025a0  "
}

.lst-kix_9ys133z7aaj4-8>li:before {
content: "\0025a0  "
}

.lst-kix_9ys133z7aaj4-5>li:before {
content: "\0025a0  "
}

.lst-kix_9ys133z7aaj4-7>li:before {
content: "\0025cb  "
}

.lst-kix_9ys133z7aaj4-2>li:before {
content: "\0025a0  "
}

.lst-kix_9ys133z7aaj4-6>li:before {
content: "\0025cf  "
}

.lst-kix_9ys133z7aaj4-3>li:before {
content: "\0025cf  "
}

.lst-kix_d52h1clnbs3c-4>li:before {
content: "\0025cb  "
}

.lst-kix_9ys133z7aaj4-4>li:before {
content: "\0025cb  "
}

.lst-kix_9ys133z7aaj4-1>li:before {
content: "\0025cb  "
}

.lst-kix_9ys133z7aaj4-0>li:before {
content: "\0025cf  "
}

ul.lst-kix_3expns8knkd9-2 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-1 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-4 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-3 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-0 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-6 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-5 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-8 {
list-style-type: none
}

ul.lst-kix_3expns8knkd9-7 {
list-style-type: none
}

.lst-kix_3expns8knkd9-0>li:before {
content: "\00200b  "
}

ul.lst-kix_9ys133z7aaj4-7 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-6 {
list-style-type: none
}

.lst-kix_3expns8knkd9-1>li:before {
content: "\0025cb  "
}

ul.lst-kix_9ys133z7aaj4-5 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-4 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-8 {
list-style-type: none
}

.lst-kix_3expns8knkd9-3>li:before {
content: "\0025cf  "
}

.lst-kix_3expns8knkd9-2>li:before {
content: "\0025a0  "
}

ul.lst-kix_9ys133z7aaj4-3 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-2 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-1 {
list-style-type: none
}

ul.lst-kix_9ys133z7aaj4-0 {
list-style-type: none
}

.lst-kix_3expns8knkd9-8>li:before {
content: "\0025a0  "
}

.lst-kix_droieg8u3yfd-8>li:before {
content: "\0025a0  "
}

.lst-kix_3expns8knkd9-7>li:before {
content: "\0025cb  "
}

.lst-kix_3expns8knkd9-4>li:before {
content: "\0025cb  "
}

.lst-kix_3expns8knkd9-5>li:before {
content: "\0025a0  "
}

.lst-kix_3expns8knkd9-6>li:before {
content: "\0025cf  "
}

.lst-kix_5u6m5vxe6pl5-2>li:before {
content: "\0025a0  "
}

.lst-kix_5u6m5vxe6pl5-3>li:before {
content: "\0025cf  "
}

.lst-kix_5u6m5vxe6pl5-0>li:before {
content: "\0025cf  "
}

.lst-kix_5u6m5vxe6pl5-8>li:before {
content: "\0025a0  "
}

.lst-kix_5u6m5vxe6pl5-1>li:before {
content: "\0025cb  "
}

ul.lst-kix_d52h1clnbs3c-5 {
list-style-type: none
}

.lst-kix_droieg8u3yfd-5>li:before {
content: "\0025a0  "
}

ul.lst-kix_d52h1clnbs3c-6 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-7 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-8 {
list-style-type: none
}

.lst-kix_f8hj6reyefk7-0>li:before {
content: "\0025cf  "
}

.lst-kix_droieg8u3yfd-6>li:before {
content: "\0025cf  "
}

.lst-kix_droieg8u3yfd-7>li:before {
content: "\0025cb  "
}

.lst-kix_f8hj6reyefk7-2>li:before {
content: "\0025a0  "
}

.lst-kix_f8hj6reyefk7-1>li:before {
content: "\0025cb  "
}

.lst-kix_f8hj6reyefk7-3>li:before {
content: "\0025cf  "
}

ul.lst-kix_d52h1clnbs3c-0 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-1 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-2 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-3 {
list-style-type: none
}

ul.lst-kix_d52h1clnbs3c-4 {
list-style-type: none
}

.lst-kix_f8hj6reyefk7-6>li:before {
content: "\0025cf  "
}

.lst-kix_droieg8u3yfd-4>li:before {
content: "\0025cb  "
}

.lst-kix_f8hj6reyefk7-5>li:before {
content: "\0025a0  "
}

.lst-kix_f8hj6reyefk7-4>li:before {
content: "\0025cb  "
}

.lst-kix_droieg8u3yfd-2>li:before {
content: "\0025a0  "
}

.lst-kix_droieg8u3yfd-3>li:before {
content: "\0025cf  "
}

.lst-kix_droieg8u3yfd-0>li:before {
content: "\0025cf  "
}

.lst-kix_droieg8u3yfd-1>li:before {
content: "\0025cb  "
}

.lst-kix_5u6m5vxe6pl5-7>li:before {
content: "\0025cb  "
}

.lst-kix_5u6m5vxe6pl5-6>li:before {
content: "\0025cf  "
}

.lst-kix_f8hj6reyefk7-7>li:before {
content: "\0025cb  "
}

.lst-kix_5u6m5vxe6pl5-4>li:before {
content: "\0025cb  "
}

.lst-kix_f8hj6reyefk7-8>li:before {
content: "\0025a0  "
}

.lst-kix_5u6m5vxe6pl5-5>li:before {
content: "\0025a0  "
}

ul.lst-kix_5u6m5vxe6pl5-2 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-1 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-4 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-3 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-6 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-5 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-8 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-7 {
list-style-type: none
}

ul.lst-kix_5u6m5vxe6pl5-0 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-8 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-5 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-4 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-7 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-6 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-1 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-0 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-3 {
list-style-type: none
}

ul.lst-kix_f8hj6reyefk7-2 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-3 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-2 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-5 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-4 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-1 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-0 {
list-style-type: none
}

li.li-bullet-0:before {
margin-left: -18pt;
white-space: nowrap;
display: inline-block;
min-width: 18pt
}

ul.lst-kix_droieg8u3yfd-7 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-6 {
list-style-type: none
}

ul.lst-kix_droieg8u3yfd-8 {
list-style-type: none
}

ol {
margin: 0;
padding: 0
}

table td,
table th {
padding: 0
}

.c10 {
border-right-style: solid;
padding-top: 0pt;
border-top-width: 0pt;
border-right-width: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
border-top-style: solid;
background-color: #ffffff;
margin-left: 36pt;
border-bottom-width: 0pt;
border-bottom-style: solid;
text-align: left;
padding-right: 0pt
}

.c0 {
border-right-style: solid;
padding-top: 0pt;
border-top-width: 0pt;
border-right-width: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
border-top-style: solid;
margin-left: 108pt;
border-bottom-width: 0pt;
border-bottom-style: solid;
text-align: left;
padding-right: 0pt
}

.c50 {
border-right-style: solid;
padding-top: 0pt;
border-top-width: 0pt;
border-right-width: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
border-top-style: solid;
margin-left: 36pt;
border-bottom-width: 0pt;
border-bottom-style: solid;
text-align: left;
padding-right: 0pt
}

.c4 {
border-right-style: solid;
padding-top: 0pt;
border-top-width: 0pt;
border-right-width: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
border-top-style: solid;
margin-left: 72pt;
border-bottom-width: 0pt;
border-bottom-style: solid;
text-align: left;
padding-right: 0pt
}

.c29 {
border-right-style: solid;
padding-top: 0pt;
border-top-width: 0pt;
border-right-width: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
border-top-style: solid;
margin-left: 144pt;
border-bottom-width: 0pt;
border-bottom-style: solid;
text-align: left;
padding-right: 0pt
}

.c35 {
border-right-style: solid;
padding: 7.2pt 7.2pt 7.2pt 7.2pt;
border-bottom-color: #ffffff;
border-top-width: 0pt;
border-right-width: 0pt;
border-left-color: #ffffff;
vertical-align: top;
border-right-color: #ffffff;
border-left-width: 0pt;
border-top-style: solid;
border-left-style: solid;
border-bottom-width: 0pt;
width: 358.5pt;
border-top-color: #ffffff;
border-bottom-style: solid
}

.c3 {
border-right-style: solid;
padding: 7.2pt 7.2pt 7.2pt 7.2pt;
border-bottom-color: #ffffff;
border-top-width: 0pt;
border-right-width: 0pt;
border-left-color: #ffffff;
vertical-align: top;
border-right-color: #ffffff;
border-left-width: 0pt;
border-top-style: solid;
border-left-style: solid;
border-bottom-width: 0pt;
width: 165pt;
border-top-color: #ffffff;
border-bottom-style: solid
}

.c28 {
margin-left: 36pt;
padding-top: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c33 {
color: #2079c7;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 19pt;
font-family: "Open Sans";
font-style: normal
}

.c13 {
color: #666666;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 9pt;
font-family: "Merriweather";
font-style: normal
}

.c25 {
color: #212529;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 7.5pt;
font-family: "Merriweather";
font-style: normal
}

.c32 {
color: #000000;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 9pt;
font-family: "Open Sans";
font-style: normal
}

.c45 {
color: #666666;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 8pt;
font-family: "Open Sans";
font-style: normal
}

.c20 {
color: #666666;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 7pt;
font-family: "Open Sans";
font-style: normal
}

.c18 {
color: #2079c7;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 9pt;
font-family: "Open Sans";
font-style: normal
}

.c19 {
color: #000000;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 7.5pt;
font-family: "Merriweather";
font-style: normal
}

.c43 {
margin-left: 72pt;
padding-top: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c5 {
color: #212529;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 7.5pt;
font-family: "Roboto";
font-style: normal
}

.c16 {
color: #666666;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 8pt;
font-family: "Merriweather";
font-style: normal
}

.c14 {
margin-left: 36pt;
padding-top: 6pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.3;
text-align: left;
margin-right: 15pt
}

.c6 {
color: #000000;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 7.5pt;
font-family: "Merriweather";
font-style: normal
}

.c24 {
color: #000000;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 24pt;
font-family: "Times New Roman";
font-style: normal
}

.c2 {
color: #000000;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 9pt;
font-family: "Open Sans";
font-style: normal
}

.c22 {
color: #666666;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 9pt;
font-family: "Merriweather";
font-style: normal
}

.c12 {
margin-left: 108pt;
padding-top: 0pt;
padding-left: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c9 {
color: #666666;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-size: 8pt;
font-family: "Merriweather";
font-style: normal
}

.c8 {
color: #212529;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 7.5pt;
font-family: "Merriweather";
font-style: normal
}

.c41 {
color: #666666;
font-weight: 400;
text-decoration: none;
vertical-align: baseline;
font-size: 6pt;
font-family: "Merriweather";
font-style: normal
}

.c40 {
-webkit-text-decoration-skip: none;
color: #1155cc;
font-weight: 700;
text-decoration: underline;
text-decoration-skip-ink: none;
font-family: "Open Sans"
}

.c30 {
padding-top: 16pt;
padding-bottom: 0pt;
line-height: 1.0;
page-break-after: avoid;
text-align: left;
margin-right: 15pt
}

.c31 {
padding-top: 5pt;
padding-bottom: 5pt;
line-height: 1.0;
page-break-after: avoid;
text-align: left;
margin-right: 15pt
}

.c44 {
color: #000000;
font-weight: 700;
text-decoration: none;
vertical-align: baseline;
font-family: "Merriweather";
font-style: normal
}

.c39 {
padding-top: 0pt;
padding-bottom: 6pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c21 {
padding-top: 0pt;
padding-bottom: 0pt;
line-height: 1.15;
text-align: left;
margin-right: 15pt
}

.c51 {
color: #666666;
text-decoration: none;
vertical-align: baseline;
font-family: "Open Sans";
font-style: normal
}

.c36 {
padding-top: 6pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c53 {
padding-top: 0pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c27 {
padding-top: 6pt;
padding-bottom: 0pt;
line-height: 1.3;
text-align: left;
margin-right: 15pt
}

.c42 {
padding-top: 16pt;
padding-bottom: 0pt;
line-height: 1.3;
text-align: left;
margin-right: 15pt
}

.c38 {
padding-top: 30pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left;
margin-right: 15pt
}

.c17 {
border-spacing: 0;
border-collapse: collapse;
margin-right: auto
}

.c34 {
font-size: 7pt;
font-family: "Open Sans";
color: #666666;
font-weight: 400
}

.c47 {
color: #212529;
font-size: 7.5pt
}

.c11 {
color: inherit;
text-decoration: inherit
}

.c7 {
padding: 0;
margin: 0
}

.c46 {
font-size: 8pt;
font-weight: 700
}

.c52 {
max-width: 2025.6pt;
padding: 28.8pt 43.2pt 43.2pt 43.2pt
}

.c1 {
font-size: 10pt;
font-weight: 400
}

.c26 {
font-size: 10pt
}

.c15 {
width: 100%;
}

.c48 {
height: 588pt
}

.c37 {
font-size: 7pt
}

.c23 {
height: 9pt
}

.c49 {
height: 4.5pt
}

.title {
padding-top: 0pt;
color: #000000;
font-weight: 700;
font-size: 36pt;
padding-bottom: 6pt;
font-family: "Merriweather";
line-height: 1.0;
text-align: left
}

.subtitle {
padding-top: 0pt;
color: #000000;
font-size: 9pt;
padding-bottom: 0pt;
font-family: "Open Sans";
line-height: 1.15;
text-align: left
}

li {
color: #666666;
font-size: 9pt;
font-family: "Merriweather"
}

p {
margin: 0;
}

h1 {
padding-top: 30pt;
font-weight: 700;
font-size: 9pt;
padding-bottom: 0pt;
line-height: 1.0;
text-align: left
}

h2 {
padding-top: 16pt;
color: #000000;
font-weight: 700;
font-size: 11pt;
padding-bottom: 0pt;
font-family: "Merriweather";
line-height: 1.0;
page-break-after: avoid;
text-align: left
}

h3 {
padding-top: 5pt;
color: #666666;
font-size: 8pt;
padding-bottom: 5pt;
font-family: "Open Sans";
line-height: 1.0;
page-break-after: avoid;
text-align: left
}

h4 {
padding-top: 8pt;
-webkit-text-decoration-skip: none;
color: #666666;
text-decoration: underline;
text-decoration-skip-ink: none;
font-size: 11pt;
padding-bottom: 0pt;
font-family: "Trebuchet MS";
line-height: 1.3;
page-break-after: avoid;
text-align: left
}

h5 {
padding-top: 8pt;
color: #666666;
font-size: 11pt;
padding-bottom: 0pt;
font-family: "Trebuchet MS";
line-height: 1.3;
page-break-after: avoid;
text-align: left
}

h6 {
padding-top: 8pt;
color: #666666;
font-size: 11pt;
padding-bottom: 0pt;
font-family: "Trebuchet MS";
line-height: 1.3;
page-break-after: avoid;
font-style: italic;
text-align: left
}
#resume{
background-color: #000;
left: 0%;
width: 85%;
padding: 1.5px;
margin-top:10px ;
display: inline;
z-index: 3;

}
#resumeDownloadLink{
display: flex;
justify-content: center;
position: absolute;
width: 100%;
top: 467px;
}







/* Public Form */

.public-form-container{
width: 90%;
padding-top: 1%;
padding-bottom: 1%;
margin-left: 5%;
}

.public-form{
width: 90%;
padding-top: 1%;
padding-bottom: 1%;
margin-left: 5%;

}
.openAi-logo-public{
margin-left: 45px;
margin-right: 45px;
padding-top: 7px;
padding-bottom: 7px;
width: 150px;
}
/* User Ai Generator Agreement */
#agreementSection{
  position: absolute;
  background-color: beige;
  width: 90%;
  left: 15px;
  height: min-content;
}

/* User Generated Imgs */
.user-imgs{
  width: 100%;
}
.user-imgs-actual-button{
  width: 100%;
}


}
